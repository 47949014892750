import React, { Component } from 'react';
import './SectionContactUs.css';
import { Container, Row, Col } from 'reactstrap';
import Form from '../Form/Form';
import renderHTML from 'react-render-html';

class SectionContactUs extends Component {
    render() {
        return (
        	<section className='section-contact-us'>
						<Container>
							<Row>
								<Col xs="12">
									<h2>{this.props.lang.title}</h2>
									<p className="text">
										{renderHTML(this.props.lang.text)}
                  </p>
								</Col>
								<Col xs="12">
									<Form form={this.props.lang.form} />
								</Col>
							</Row>
						</Container>
					</section>
        )
    }
}

export default SectionContactUs;
