import React, { Component } from 'react';
import './SimplePhoto.css'
import renderHTML from 'react-render-html';
import { Col } from 'reactstrap';

class SimplePhoto extends Component {
  render() {
    return (
      <Col xs="4">
        <div className='simple-photo'>
          <img alt={this.props.photo.alt} src={this.props.photo.src} />
          <h3>
            {renderHTML(this.props.photo.title)}
          </h3>
          <p>
            {renderHTML(this.props.photo.description)}
          </p>
        </div>
      </Col>
    )
  }
}

export default SimplePhoto;
