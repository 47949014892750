import React, {Component} from 'react';

class Select extends Component {
	handleInputChange(event){
		this.props.onChange({
			name: event.target.name,
			value: event.target.value
		})
	}
	render(){
		return (
			<select
				id={this.props.options.id}
				name={this.props.options.name}
				className="form-control"
				defaultValue={this.props.options.default || 0}
				onChange={this.handleInputChange.bind(this)}>
				<option
					value={this.props.options.default || 0}
					disabled>
					{this.props.options.default || ""}
				</option>
				{
					this.props.options.values.map(option => {
						return (
							<option
								key={option}
								value={option}
								label={option}>{option}</option>
						);
					})
				}
			</select>
		)
	}
}

export default Select;
