import React, { Component } from 'react';
import Splash from '../Splash/Splash.js';
import './App.css';

import Utils from '../_common/Utils.js';
import Lang from '../_common/lang.json';
import SectionClaim from "../SectionClaim/SectionClaim";
import SectionText from "../SectionText/SectionText";
import SectionVideoText from "../SectionVideoText/SectionVideoText";
import SectionVideoTextReverse from "../SectionVideoTextReverse/SectionVideoTextReverse";
import SectionImageText from "../SectionImageText/SectionImageText";
import SectionImageTextReverse from "../SectionImageTextReverse/SectionImageTextReverse";
import SectionContactUs from "../SectionContactUs/SectionContactUs";
import Footer from "../Footer/Footer";
import SectionRevolution from "../SectionRevolution/SectionRevolution";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Splash lang={Lang.claim} />
        <SectionText lang={Lang.chiSiamo} />
        <SectionText lang={Lang.iNostriProgetti} />
        <SectionVideoText lang={Lang.progettoAtleticaMe} />
        <SectionImageTextReverse lang={Lang.progettoCallRoom} />
        <SectionImageText lang={Lang.fourclubs} />
        <SectionImageTextReverse lang={Lang.virtualbz} />
        <SectionImageText lang={Lang.gpgiovanile} />
        {/*<SectionImageText lang={Lang.atleti} />*/}
        <SectionText lang={Lang.comeSviluppiamo} />
        {/*<SectionRevolution lang={Lang.riconoscimenti} />*/}
        <SectionContactUs lang={Lang.contactUs} />
        <SectionText lang={Lang.citazione} />
        <Footer lang={Lang.footer} />
      </div>
    );
  }
}

export default App;
