import React, { Component } from 'react';
import ListPhotos from '../ListPhotos/ListPhotos.js'
import './SectionRevolution.css'

import { Container, Row, Col } from 'reactstrap';

class SectionRevolution extends Component {
  render() {
    return (
      <section className='section-revolution'>
        <Container>
          <Row>
            <Col>
              <h2>{this.props.lang.title}</h2>
              <div>
                <p>
									{this.props.lang.description}
                </p>
              </div>
              <Container>
                <Row>
                  <ListPhotos gallery={this.props.lang.gallery} />
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default SectionRevolution;
