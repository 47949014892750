import React, {Component} from 'react';
import Label from './Label.js';
import CheckBox from './CheckBox';
import Input from './Input';
import TextArea from './TextArea';
import Select from './Select';

class FormElement extends Component {
  state = {
    'checkbox': CheckBox,
    'input': Input,
    'textarea': TextArea,
    'select': Select
  }
  render() {
    const element = this.props.element
    const CustomElement = this.state[element]
    const classes = this.props.classes || 'col-12'
    const isCheckbox = element === 'checkbox' ? 'd-flex flex-row-reverse justify-content-end': ''

    return (
      <div className={`form-group ${classes} ${isCheckbox} pr-0 pr-sm-0 pr-md-0 pr-lg-3`}>
        <Label name={this.props.name} title={this.props.label} />
        <CustomElement
          options={{...this.props.options, name:this.props.name, id:this.props.name}}
          onChange={this.props.onChange.bind(this)}
        />
      </div>
    );
  }
}

export default FormElement;
