import React, { Component } from 'react';
import './SectionImageTextReverse.css';
import { Container, Row, Col } from 'reactstrap';
import renderHTML from 'react-render-html';

class SectionImageTextReverse extends Component {
    render() {
        return (
          <section className='section-image-text-reverse'>
						<Container className='full-height'>
							<Row>
								<Col xs={{ size: 12, order: 2 }} sm={{ order: 2 }} md={{ order: 2 }} lg={{ order: 1, size: 6 }}>
									<img className="img-fluid" alt="unsplash" src={this.props.lang.image} />
								</Col>
								<Col xs={{ size: 12, order: 1 }} sm={{ order: 1 }} md={{ order: 1 }} lg="6">
									<h2 className="">
										{this.props.lang.title}
									</h2>
									<p className="text">
											{renderHTML(this.props.lang.text)}
									</p>
								</Col>
							</Row>
						</Container>
					</section>
        )
    }
}

export default SectionImageTextReverse;
