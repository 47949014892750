import React, { Component } from 'react';
import './SectionVideoTextReverse.css';
import { Container, Row, Col } from 'reactstrap';
import renderHTML from 'react-render-html';

class SectionVideoTextReverse extends Component {
    render() {
        return (
          <section className='section-video-text-reverse'>
            <Container className='full-height'>
                <Row>
                    <Col xs="12" lg="6">
                        <video width="600" autoPlay loop muted playsInline>
                            <source src={this.props.lang.video} type="video/mp4" />
                        </video>
                    </Col>
                    <Col xs="12" lg="6">
                        <h2 className="">
                            {this.props.lang.title}
                        </h2>
                        <p className="text">
                            {renderHTML(this.props.lang.text)}
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
        )
    }
}

export default SectionVideoTextReverse;
