import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import './SectionClaim.css';
import renderHTML from 'react-render-html';

class SectionClaim extends Component {
  render() {
    return (
      <div className="claim-content d-flex align-items-center">
        <p className="head">{this.props.lang.head}</p>
        <Row className="justify-content-md-center">
          <Col xs="12" lg="9">
            <div className="main-content">
              <h1>{this.props.lang.title}</h1>
                <p className="text mb-lg-5">{renderHTML(this.props.lang.text)}</p>
                {
                this.props.lang.ctas.map((cta, i) => {
                    return <div class="btn-container"><a target={cta.target} href={cta.link} className={cta.class}>{cta.text}</a></div>
                })
                }
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default SectionClaim;
