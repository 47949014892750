import React from 'react';

const Label = (props) => {
		return (
			<label htmlFor={props.name}>
				{
					Array.isArray(props.title) ?
						props.title.map(lbl => {
							let content = (lbl.link) ? (<a href={lbl.link}>{lbl.text}</a>) : (lbl.text)
							return <span key={lbl.id} className={lbl.class}>{content}</span>
						}) : props.title
				}
			</label>
		)
}

export default Label;
