import React from 'react';


const Button = (props) => {
	return(
	<button
		disabled={props.disabled}
		type={props.type}
		style= {props.style}
		className = {props.class==='primary' ? 'btn btn-primary' : 'btn btn-secondary'}
		onClick= {props.action} >
		{props.title}
	</button>)
}


export default Button;
