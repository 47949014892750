import React, { Component } from 'react';
import SimplePhoto from '../SimplePhoto/SimplePhoto.js'
import './ListPhotos.css'

class ListPhotos extends Component {
  render() {
    return (
      <div className="image-box">
        {
          this.props.gallery.map((elem, i) => {
            return <SimplePhoto key={i} photo={elem} />
          })
        }
      </div>
    )
  }
}

export default ListPhotos;
