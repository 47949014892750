import React, { Component } from 'react';
import './SectionImageText.css';
import { Container, Row, Col } from 'reactstrap';
import renderHTML from 'react-render-html';

class SectionImageText extends Component {
    render() {
        return (
          <section className='section-image-text'>
						<Container className='full-height'>
							<Row>
								<Col xs="12" lg="6">
									<h2 className="">
										{this.props.lang.title}
										</h2>
									<p className="text">
										{renderHTML(this.props.lang.text)}
									</p>
								</Col>
								<Col xs="12" lg="6">
									<img className="img-fluid" alt="unsplash" src={this.props.lang.image} />
								</Col>
							</Row>
						</Container>
					</section>
        )
    }
}

export default SectionImageText;
