import React, {Component} from 'react';

import FormElement from '../_components/FormElement.js';
import Button from '../_components/Button.js';

class Form extends Component {
	constructor(props){
		super(props)
		this.state = this.props.form.fields.reduce((obj, item) => {
			obj[item.name] = null;
			return obj;
		}, {})
	}
	resetState() {
		const resettedState = this.props.form.fields.reduce((obj, item) => {
			obj[item.name] = null;
			return obj;
		}, {})
		this.setState({...resettedState}, () => {})
	}
	onChange(event) {
		let value = event.value;
  	let name = event.name;
		this.setState({
			[name]: value
		}, () => {})
	}
	handleFormSubmit(fields) {
		return function (e) {
			e.preventDefault();

			fetch('https://phpmailer.atletica.me/send-email-sportwave.php', {
				method: "POST",
				body: JSON.stringify(fields),
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data => {
					alert(data['message'])	
				})
			}).catch(error => {
				error.json().then(data => {
					alert(data['message'])
				})
			})
		}
	}
  render() {
    return (
        <form className="container-fluid row" onSubmit={this.handleFormSubmit(this.state)}>
					{
						this.props.form.fields.map((field) => {
							return <FormElement
								key={field.name}
								{...field}
								onChange={this.onChange.bind(this)}
							/>
						})
					}

					<div className='form-group col-12'>
						<Button
	            action={this.handleFormSubmit}
							type="submit"
	            class="primary"
	            title={this.props.form.submit}
	          />

	          <Button
							type="reset"
	            title={this.props.form.clear}
							action={() => {this.resetState()}}
	          />
					</div>
        </form>
    );
  }
}

export default Form;
