import React, {Component} from 'react';
import './Footer.css';

class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="py-4">
          <div className="credits">
            {
              Array.isArray(this.props.lang.credits.text) ?
                this.props.lang.credits.text.map(txt => {

                  let content = (txt.link) ? (<a href={txt.link}>{txt.text}</a>) : (txt.text)

                  // console.log(content);

                  return <p key={txt.id} className={txt.class}>
                      {content}
                    </p>
              }) : ''
            }
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer;
